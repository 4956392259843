import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

type Props = {
  name: string;
  imagePath: string;
  technologies: Array<string>;
  description: string;
};

const ProjectCard: React.FC<Props> = (props) => {
  const storage = getStorage();

  const [projectImage, setProjectImage] = useState("");
  const [techs, setTechs] = useState<string[]>([]);

  useEffect(() => {
    getDownloadURL(ref(storage, props.imagePath)).then((result) => {
      setProjectImage(result);
    });
    if (Array.isArray(props.technologies) && props.technologies.length > 0) {
      props.technologies.map((el) => {
        getDownloadURL(ref(storage, el)).then((result) => {
          setTechs((techs)=>[...techs,result])
        });
      });
    }
  }, []);

  if (techs.length === 0) {
    return <>Loading...</>;
  }
  return (
    <article className="flex drop-shadow-xl flex-col rounded-3xl items-center space-y-0 flex-shrink-0 w-72  md:w-[600px] xl:w-[700px] snap-center bg-lbrown bg-gradient-to-bl from-dbrown/30 to-transparent p-5 md:p10 hover:opacity-100 opacity-100 cursor-pointer transition-opacity duration-200 ">
      <motion.img
        initial={{ opacity: 0, y: -100 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 1.2 }}
        className=" w-48 h-24 md:w-56 md:h-28 xl:w-96 xl:h-96 mb-2 object-contain object-center"
        src={projectImage}
        alt=""
      />
      <div className="w-full px-0 md:px-10">
        <div className=" md:flex md:justify-between items-center">
          <div>
            <h4 className="text-lg md:text-3xl font-light text-black">
              {props.name}
            </h4>
            <div className="flex space-x-2 my-2">
              {techs.map((tech, index) => (
                <img
                  key={index}
                  className="h-6 w-6 md:h-10 md:w-10 rounded-full object-contain"
                  src={tech}
                  alt=""
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <p className="md:px-10 text-black space-y-2 text-justify ml-0 text-sm md:text-lg">
        {props.description}
      </p>
    </article>
  );
};

export { ProjectCard };
