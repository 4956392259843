import { motion } from "framer-motion";
import { Project } from "../Pages/Index";
import { ProjectCard } from "./ProjectCard";

const Projects: React.FC<{ projects: Project[] }> = (props) => {
  if (props === null) {
    return <>Loading..</>;
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      className="snap-center h-screen flex relative overflow-hidden flex-col text-left md:flex-row max-w-full px-10 justify-evenly mx-auto items-center"
      id="projects"
    >
      <h3 className="absolute top-20 md:top-24 uppercase tracking-[20px] text-dbrown text-xl md:text-2xl">
        project
      </h3>

      <div className="w-full h-3/4 md:h-2/3 md:w-full text-left pb-5 md:pb-10 flex space-x-5 overflow-x-scroll p-10 snap-x snap-mandatory scrollbar-thin scrollbar-track-gray-400/20 scrollbar-navbar">
        {props.projects.map((project, index) => (
          <ProjectCard
            key={index}
            name={project.name}
            imagePath={project.image}
            technologies={project.technologies}
            description={project.description}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default Projects;
