import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

type Props = {
  imagePath: string;
  percent: number;
};

const SkillBubble: React.FC<Props> = (props: Props) => {
  const storage = getStorage();
  const [image, setImage] = useState("");

  useEffect(() => {
    console.log(props.imagePath)
    if (props.imagePath) {
      getDownloadURL(ref(storage, props.imagePath)).then((result) => {
        setImage(result);
      });
      console.log(image)
    }
  }, []);

  return (
    <div className="group relative flex cursor-pointer">
      <motion.img
        initial={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        whileInView={{ opacity: 1 }}
        src={image}
        className="rounded-full border border-dbrown/50 w-16 h-16  md:w-28 md:h-28 xl:w-32 xl:h-32 filter group-hover:grayscale transition duration-300 ease-in-out object-contain"
      />
      <div
        className="absolute opacity-0 group-hover:opacity-80 transition duration-300 ease-in-out 
      group-hover:bg-white h-16 w-16 md:w-28 md:h-28 xl:w-32 xl:h-32 rounded-full z-0"
      >
        <div className="flex items-center justify-center h-full">
          <p className="text-xl md:text-3xl font-bold text-black opacity-100">
            {props.percent}%
          </p>
        </div>
      </div>
    </div>
  );
};

export default SkillBubble;
