import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const NavBar:React.FC = () => {

  return (
    <motion.nav className='z-50 sticky top-0 lg:p-2 flex mb-2 justify-center items-center bg-lbrown'>
      <Link reloadDocument to='#hero'>
        <motion.span
          initial={{ y: '-100vh' }}
          animate={{ y: '0vh' }}
          transition={{ duration: 1 }}
          className='lg:flex rounded-xl lg:text-5xl text-2xl font-bungee-shade text-dbrown font-bold'
        >
          Daniel Istvan
        </motion.span>
      </Link>
    </motion.nav>
  );
};

export default NavBar;
