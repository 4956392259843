import { motion } from "framer-motion";
import React, { useEffect } from "react";
import {ExperienceCard} from "./ExperienceCard";
import { Experience } from "../Pages/Index";


export const WorkExperience:React.FC<{experiences:Experience[]}> = (props) => {

if (props === null) {
  return <>Loading..</>;
}
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      className="snap-center h-screen flex relative overflow-hidden flex-col text-left md:flex-row max-w-full px-10 justify-evenly mx-auto items-center"
      id="experience"
    >
      <h3 className="absolute top-20 md:top-24 uppercase tracking-[20px] text-dbrown text-xl md:text-2xl">
        Experience
      </h3>

      <div className="w-full h-3/4 md:h-2/3 md:w-full text-left pb-5 md:pb-10 flex space-x-5 overflow-x-scroll p-10 snap-x snap-mandatory scrollbar-thin scrollbar-track-gray-400/20 scrollbar-navbar">
      {props.experiences.map((experience, index) => (
          <ExperienceCard
            key={index}
            position={experience.position}
            companyName={experience.company}
            dateStart={experience.dateStart}
            dateEnd={experience.dateEnd}
            imagePath={experience.image}
            technologies={experience.technologies}
            description={experience.description}
          />
        ))}
      </div>
    </motion.div>
  );
}
