import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Index from "./Pages/Index";

function App() {
  return (
    <div
      className="h-screen snap-y snap-mandatory
    overflow-y-scroll overflow-x-hidden z-0 scrollbar-thin scrollbar-track-gray-400/20 scrollbar-thumb-navbar"
    >
      <BrowserRouter>
        <Index />
      </BrowserRouter>
    </div>
  );
}

export default App;
