import { Link } from "react-router-dom";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useEffect, useState } from "react";

type Props = {
  image: string;
};

const Hero: React.FC<Props> = (props) => {
  const storage = getStorage();
  const [profilePic, setProfilePic] = useState("");
  //Külön komponenesbe kivinni
  const [text, count] = useTypewriter({
    words: [
      `Hi, my name's Dániel István`,
      "I like riding my 🚲",
      "I love to 👨‍💻",
      `Hi, my name's Dániel István`,
    ],
    loop: 1,
    delaySpeed: 2000,
  });

  useEffect(() => {
    getDownloadURL(ref(storage, props.image)).then((result) => {
      setProfilePic(result);
    });
  }, []);

  return (
    <div className="h-screen flex flex-col space-y-8 items-center justify-center text-center overflow-hidden snap-center">
      <img
        className="w-auto h-80 rounded-full mx-auto object-cover"
        src={profilePic}
        alt=""
      />

      <div className="z-20">
        <h2 className="text-sm uppercase text-gray-500 pb-2 tracking-[10px] md:tracking-[15px]">
          Software Developer
        </h2>
        <h1 className="text-2xl md:text-5xl lg:text-6xl font-semibold px-10">
          <span className="mr-3 text-dbrown">{text}</span>
          <Cursor cursorColor="#FBACBE" />
        </h1>

        <div className="pt-5">
          <Link reloadDocument to="#hero">
            <button className="heroButton">About</button>
          </Link>
          <Link reloadDocument to="#experience">
            <button className="heroButton">Experience</button>
          </Link>
          <Link reloadDocument to="#skills">
            <button className="heroButton">Skills</button>
          </Link>
          <Link reloadDocument to="#projects">
            <button className="heroButton">Projects</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
{
  /* */
}
export default Hero;
