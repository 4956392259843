import { motion } from "framer-motion";
import SkillBubble from "./SkillBubble";
import { Skill } from "../Pages/Index";

export const Skills: React.FC<{skills:Skill[]}> = (props) => {
  return (
    <motion.div
      className="h-screen flex relative flex-col text-center md:text-left xl:flex-row max-w-[2000px] xl:px-10 min-h-screen justify-center xl:space-y-0 mx-auto items-center snap-center"
      id="skills"
    >
      <h3 className="absolute top-20 md:top-24 uppercase tracking-[20px] text-dbrown text-xl md:text-2xl">
        Skills
      </h3>
      <h3 className="absolute top-36 uppercase tracking-[3px] text-dbrown text-sm">
        Hover over a skill for current profiecency
      </h3>
      <div className="grid grid-cols-4 gap-5">
        {props.skills.map((skill,index)=>(
          <SkillBubble imagePath={skill.imagePath} percent={skill.percent} key={index} />
        ))}
      </div>
    </motion.div>
  );
};
