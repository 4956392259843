// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAnmC8q9xPEKc107UlXM_Ka6CoA39GxgNE",
  authDomain: "personal-website-95718.firebaseapp.com",
  projectId: "personal-website-95718",
  storageBucket: "personal-website-95718.appspot.com",
  messagingSenderId: "1091661790655",
  appId: "1:1091661790655:web:c13bd6c55b76d63eddb50a",
  measurementId: "G-G3FW7HHCTT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export {firebaseConfig, db};